import React,{useEffect} from 'react'
import CarouselFade from '../components/HomeCarousel';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel';
import Plumbing from '../components/plumbing';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaQuoteLeft, FaArrowAltCircleRight,FaCheckCircle} from 'react-icons/fa';
import {BsBarChartFill} from 'react-icons/bs';
import { IoIosMusicalNote } from "react-icons/io";
import VisionCarousel from '../components/Vision Carousel';
import CoreValues from '../components/CoreValues';
let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}

const Home = () => {

useEffect(()=>{
document.title = 'Amazing Grace Acapella';

},[]);


  return (
    <>
    <section>
      <CarouselFade></CarouselFade>
    </section>
     {/* <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">About Us</h2>
          <div className='content-container vm-container'>
                 <div className='vision-mission-content vision-mission-content-a'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info vision-mission-info-a'>
                          <h3 className='title-h3'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          <p>
                            Our business brings advanced telemetric (GPS tracking combined with wireless communication) products to the consumer market. Our product allows consumers to track the location and manage their vehicle with their mobile phone/computers and be notified when unexpected events such as a hijacking, accident or break-in occur.</p>
                            <p>  Our product is positioned as the next generation car alarm - silent with features that are useful every day, not just when a theft is attempted. We also support personal tracking devices such as GPS enabled phones to further improve the "peace of mind" of our customers. We will also be adding support for other wireless networks to improve the coverage of our products.
</p>
                          
                          <h3 className='title-h3'>Advantages of our solutions</h3>
                          <div className='vm-line'></div>
                          <p>
                          Our system has been proven to be efficient and highly competitive. It assists our clients achieve the following goals:
</p>
<ul>
<li>Cut operational costs.</li>
<li>Increase revenue.</li>
<li>Monitor and control the movement of the vehicle.</li>
<li>Save lives.</li>
<li>Fuel control.</li>

</ul>


                    </article>

                    


                 </div>

          </div>
    </section> */}
     {/* <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./images/9.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'4rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Why Choose Us?</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span><strong>Wanway Solutions Ltd</strong> is one of the leaders in tracking services and is committed to ensuring that you are always aware of the location of your asset. You monitor it yourself and therefore you can’t lose it!

</p>
<p>We provide excellent customer care services and follow-up on our customers through maintenance and regular upgrades on our systems.

</p>
        </div>
    </section> */}
    <section className='section-cover bg-cover bg-cover-a'>
     <IoIosMusicalNote className='icon-a' /> <IoIosMusicalNote className='icon-b'/>
        <div className='content-container content-container-a'>
          <h2 className="home-h2 home-h2-a">Get to know us</h2>
          {/* <div className='line1'>
                    <div className='line2'></div>
          </div> */}
          <p className='home-p'><strong>Amazing Grace Acapella (AGA)</strong> is an Anglican full-based-service acapella team of all seasons based in Kyebando - along Gayaza Road, composed of 4 Gents & 1 Lady to accomplish our passion of God’s ministry, support of vulnerable children in their education, welfare and spiritual transformation through the acapella touch. </p>

                 <p className='home-p'>AGA is an origin of St. Paul's C.O.U (Kyebando) in Kazo - Archdeaconry (Namirembe Diocese) established in 2015 by (ANDREW BAKKABULINDI - MCIPS) with a main purpose of serving God through Acapella Gospel music. 
</p>
{/* <p className='home-p'>
The purpose of making Acapella Gospel Music is to share the Gospel of Jesus Christ through acapella songs that encourage believers in their walk with Christ. Additionally, acapella gospel music is also a very good tool in reaching non-believers and the broken-hearted who enjoy these songs and demand more.</p> */}
 {/* <p className='home-p'>
Our mission is to inspire, win more souls to Christ and the weary hearts of both old & young youths by preaching the gospel through acapella music so as to reach down to people’s hearts.</p> */}
 
 </div>

    </section>
    <section className='section-cover who-we-are-cover who-we-are-cover-a' >
      {/* <h2 className="home-h2 wwa-h2">About Us</h2> */}
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          {/* <VisionCarousel></VisionCarousel> */}
                          <img src='./images/7.jpg' className='vision-image'></img>

                    </article>

                    <article className='vision-mission-info vision-mission-info-b'>
                          <h3 className='title-h3 title-h3-a'>Our Vision</h3>
                          
                           <div className='vm-line'></div>
                          {/* <p>
                           “The only way to start is to start” <br></br>(Engeri emu ey’okutandika kwe kutandika)
</p> */}
<p>To share the Gospel of Jesus Christ through acapella songs that encourage the believers, non-believers & broken hearted in their walk with Christ.</p>
                          
                         

                    </article>

                    


                 </div>

          </div>
    </section>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient(rgba(0, 22, 33, 0.7), rgba(0, 22, 33, 0.7)), url("./images/1.jpg")`,
    // backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'5rem 0'

    }}>
          <div className='quote-content'>
             <h2 className='quote-h3'>Our Slogan</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span> The only way to start is to start <br></br>(Engeri emu ey’okutandika kwe kutandika).

</p>
            <h2 className='quote-h3 quote-h3-b'>Our Mission</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>To inspire, win more souls to Christ and the weary hearts of both old & young youths by preaching the gospel through acapella music so as to reach down to people’s hearts.

</p>
        </div>
    </section>
     
    <section className='section-cover section-cover-core-values' >
      <h2 className="home-h2 home-h2-a home-h2-b">Our Core Values</h2>
      <div className='container'>
              <CoreValues/>
      </div>

    </section>
  
    
 {/* <section className='section-cover'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Goals</h2>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>More and more of our clients are turning to us for our distinctive ability to implement
innovative project management techniques and to serve as a reliable provider of
knowledge-driven solutions for their complex construction and Supply projects.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description goal-description1'><strong> REJO ENGINEERING LIMITED</strong> began as a general works contractor in 2014. Over the
years, the company has undertaken many challenging projects and accumulated skills, know-how and
experiences in delivering building projects, water projects, road and bridge construction
projects and build solutions, project management services, building trades and related
engineering works.
<br></br> <br></br>
Today, <strong>REJO ENGINEERING LIMITED</strong> takes on the role of main contractor for small,
medium and large size construction projects and performs project management services to
coordinate specialist trades for industrial/commercial projects. We also provide design inputs
and engineering solutions as value-add services to our clients.
<br></br> <br></br>
We take pride in our delivery, thus our clients can always be assured that only the most experienced and qualified people are serving them, all the time.</p>
                     </div>


                </div>
                   

           </article>

           <article className='goals-slides'>
               <GoalsCarousel></GoalsCarousel>
               

           </article>

       </div>

  </section> */}

   {/* <section className='home-service-cover'>
    <h2 className='home-h2 home-service-h2'>Overview of  
    Optical Fiber our expertise</h2>
     <h2 className='home-h2 home-service-h2'>Our Services</h2>
    <div className='line1'> 
    <div className='line2'></div>
    </div>
    
       <div className='home-services-data'>
       <div className='home-services-info '>


       
       
          <div className='home-service-box'>
            <div className='home-service-icon-cover'>
          <span className='home-service-icon'><FaHandshake></FaHandshake></span>
          </div>
          <h3 className='home-service-title'>CCTV Installation</h3>
          <img src='./images/16.jpg' className='home-service-image'></img>
          <Link to='#' className='home-link'>See Details</Link>
          <p>
            We are passionate about our work and our strength lies in working as a team to engage with our clients and deliver first class customer service and the highest standards of excellence.
            </p>

        </div>
   


    </div>
        
<Link to='features' className='home-btn' onClick={scrollFunc}>Key Features</Link>

        </div>

  </section> */}
 {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.863)), url("./images/4.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Objectives</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list1'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'> To provide the most efficient installation, repair
and maintenance solutions for clients’
dependable work continuity.
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To ensure timely, correct and best quality
delivery of services and supplies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To grow and maintain trusted relationships
with our clients, partners and government.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaCheckCircle></FaCheckCircle>
                          </span>
                          <p className='goal-description goal-description-a'>To foster client up-to-date market awareness.

</p>
                     </div>
                    
                </div>
                   

           </article>

           




       </div>

  </section> */}

  


  {/* <section className='section-cover clients-cover' >
          <h2 className="home-h2">Our Clients</h2>
           <div className='line1'> 
           <div className='line2'></div>
           </div>
          <p className='clients-para'></p>
        <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/1.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/2.jpg' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/3.png' alt='client' className='client-image'></img>
           </article>
           <article className='client'>
                      <img src='./images/4.png' alt='client' className='client-image'></img>
           </article>

            <article className='client'>
                      <img src='./images/5.png' alt='client' className='client-image'></img>
           </article>

          </div> 
    </section> */}
   
     {/* <section className='section-cover bg-cover'>
        <div className='content-container '>
          <h3 className='title-h3'>How Our System Works?</h3>
              <div className='vm-line'></div>
                 <p className='home-p home-p-a'>
      <strong>1. The Tracking Unit:</strong> A tracking unit is covertly installed in the vehicle. This is done to make sure that the unit is not sabotaged or damaged in any case. The tracking unit receives GPS signal and the on-board processor translates and encodes these signals. This information together with other vehicle status such as Current speed, ignition, battery voltage, mileage etc. are all packed together into a single transmission data, encoded, encrypted and transmitted directly to our Control Center using the widely available GSM Network. 

</p>
<p className='home-p home-p-a'>
     <strong>2. The Control Center:</strong> Our Control Center receives the data sent from the tracking unit, decodes and decrypts the message with all the information about the vehicle and feeds it into the Control Center Application (CCA). The Control Center software uses this data to identify the transmitting unit(s) details, locations and maps. 
</p>

<p className='home-p home-p-a'>
   <strong>3. Tracking Website:</strong>  The web server receives information from the Central Control Application which is equipped with powerful map engine and a map server. It translates the data and displays on the map the different status of the vehicle. The site is fully secured to avoid compromising your vehicle security. 

</p>





 
        </div>

    </section> */}
       {/* <section className='section-cover section-cover2' style={{ backgroundImage: `linear-gradient(  rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./images/11.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
       <div className='content-container goals-container'>
           <article className='goals-text goals-text1 goals-text2 goals-text3'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Core Values</h2>
              <h3 className='title-h3'>Our Clientele</h3>
              <div className='vm-line'></div>
              <div className='goals-list goals-list-a'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Individuals. 
</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Tour & Travel Operators.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Transportation & Haulage Companies.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'> Taxi Operators. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Delivery & Distribution Companies. </p>
                     </div>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>Leasing & Car Rental Companies.</p>
                     </div>

                </div>
                   

           </article>

       </div>
                    
  </section> */}
  
    </>
  )
}

export default Home