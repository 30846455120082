import React from 'react'

let ValuesData = [

{title: "Quality",      
content: "It is a core value of Amazing Grace Acapella to promote and present repertoire of the highest musical, artistic and poetic acapella quality projects. Furthermore, it is a core value of our organization to reflect the highest level of excellence in every area of our administrative and organizational life."
},

{title: "Diversity",      
content: "Amazing Grace Acapella always wishes to reflect a worldwide diversity of acapella experience and expression through our programs, choice of venues and promotional efforts."
},
{title: "Opportunity",      
content: "Amazing Grace Acapella aims at providing opportunities for singers, both youth and adults, to perform in excellent choral ensembles appropriate to their interest and abilities. It is a core value of the Amazing Grace Acapella to equip music professionals with resources to help them increase their musical and leadership skills toward their own pursuit of excellence."
},
{title: "Accessibility",      
content: " Amazing Grace Acapella brings down all barriers to choral enjoyment and performance. This includes a “no barriers” policy toward membership tuition, concert tickets and recordings. No person will ever be denied the benefits of the Amazing Grace Acapella based on ability to pay. Further, the Amazing Grace Acapella does not discriminate on the basis of sex, age, race, sexual orientation, religion and national origin in its employment and membership policies."
},
{title: "Education",      
content: "Amazing Grace Acapella also provides educational opportunities for choral enthusiasts and music professionals through our performing ensembles, consulting and partnering efforts. Further, through our varied performance ensembles, the Amazing Grace Acapella is committed to music education for all ages from youth to adult."
},
{title: "Involvement & Team Work",      
content: "Amazing Grace Acapella encourages all its members to be involved in the life of our wider community through performance partnerships with community, school and faith-based music organizations, and through concerts that raise awareness and funds for organizations that seek to alleviate human suffering and promote increased opportunity for all people and all members of Amazing Grace Acapella."
}
];

export default ValuesData;