import React, {useEffect} from 'react'

const Team = () => {

useEffect(()=>{
document.title = 'AGA Foundation | Amazing Grace Acapella';

},[]);

  return (
    <>
       <section className='section-cover team-cover'>
        
          <div className='content-container foundation-content'>
<h2 className="home-h2 home-h2-a">AGA Foundation</h2>
                <p className='home-p'>AGA Foundation raises funds through acapella ministry to support;</p>
            <p className='home-p'>a) Vulnerable Children in their Education and welfare.
All Children who have lost their parents or beloved ones.
Amazing Grace Acapella brings an impact in the society / community that we 
live in by empowering and transforming these children to become the next 
generation of Ugandan leaders. We believe 
if we join hands together, we can create an impact in the lives and educate the 
vulnerable young girls and boys.</p>
<p className='home-p'>Apparently, we also conduct community 
outreach to vulnerable children in support of their welfare among other materials needs so as to live a better life in the community where they live.</p>
   <p className='home-p'>b) Skilling & Empowering both the boy and girl child. 
AGA believes that all those Children that dropped out of school as a result COVID-19 impact can still be useful and impactful in the society. So as part of our contribution to the 17 SDGs. We are set to reduce on the unemployment rates of youthful girls and boys who were affected by COVID-19. The skills of baking, tailoring, weaving, shoe-making and hair-dressing will help them to either start up small businesses which can later grow to employ others. </p> 
 <p className='home-p'>We came up with this idea as one that will help the girl & boy child create wealth and also help uplift others by creating jobs when their businesses expand. The initiative is set to train and equip all the girls in the affected parts of Kampala among others who require these skills. </p>    
        </div>
        </section> 
    </>
  )
}

export default Team